<template>
    <div class="index">
        首页
    </div>
</template>
<script>
    export default{
        data(){
            return{
                
            }
        }
    }
</script>